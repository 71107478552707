import React, { useState } from 'react'
import { Container, Button, ButtonGroup } from 'reactstrap'
import flatten from 'flat'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { getUserLanguage } from 'common/Language.js'

// COMPONENTS
import FormattedHtmlMessage from 'Components/common/FormattedHtmlMessage'

// STRINGS
import strings_en from 'strings/en.json'
import strings_no from 'strings/no.json'
import strings_pl from 'strings/pl.json'

const localization: any = {
  en: flatten(strings_en.tos),
  no: flatten(strings_no.tos),
  pl: flatten(strings_pl.tos),
}

const TosPage = () => {
  const [selectedOption, setSelectedOption] = useState('usa')

  const renderTerms = () => {
    switch (selectedOption) {
      case 'usa':
        return <FormattedHtmlMessage id="htmlContentUSA" />
      case 'uk':
        return <FormattedHtmlMessage id="htmlContentUK" />
      case 'rest':
        return <FormattedHtmlMessage id="htmlContentNorwayAndRest" />
      default:
        break
    }
  }

  return (
    <IntlProvider messages={localization[getUserLanguage()]}>
      <Container>
        <section className="py-8">
          <h1 className="text-center pb-2">
            <FormattedMessage id="header" />
          </h1>
          <ButtonGroup className="pt-5 pb-8">
            <Button
              className="mr-2 pricing-currency-button"
              color={selectedOption === 'usa' ? 'secondary' : 'default'}
              onClick={() => setSelectedOption('usa')}
            >
              <FormattedMessage id="USA" />
            </Button>
            <Button
              className="pricing-currency-button"
              color={selectedOption === 'uk' ? 'secondary' : 'default'}
              onClick={() => setSelectedOption('uk')}
            >
              <FormattedMessage id="UK" />
            </Button>
            <Button
              className="ml-2 pricing-currency-button"
              color={selectedOption === 'rest' ? 'secondary' : 'default'}
              onClick={() => setSelectedOption('rest')}
            >
              <FormattedMessage id="NorwayAndRest" />
            </Button>
          </ButtonGroup>
          {renderTerms()}
        </section>
      </Container>
    </IntlProvider>
  )
}

export default TosPage
